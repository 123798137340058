import { createSlice } from '@reduxjs/toolkit'
import {
    SweepstakeVarietyCreate,
    SweepstakeVarietyList,
    SweepstakeVarietyById
} from './thunk'

export const AdditionalDataForm = {
    additional_data:'',
    points:'',
    type:''
}
export const additionalDataForm = {
    title:'',
    category:'',
    additional_data: [{
        additional_data:'BOC',
        points:'',
        type:''
    },{
        additional_data:'BOC',
        points:'',
        type:''
    }]
}

export const initialState = {
    data: [],
    loading: true,
    form: additionalDataForm
}
const SweepstakeVariety = createSlice({
    name: 'SweepstakeVariety',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(SweepstakeVarietyList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(SweepstakeVarietyList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.form = additionalDataForm
            state.data = data   
            state.loading = false
        })
        builder.addCase(SweepstakeVarietyList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(SweepstakeVarietyCreate.pending, (state) => {
            state.data = []
            state.loading = true
        })
        builder.addCase(SweepstakeVarietyCreate.fulfilled, (state, action) => {
            const { data } = action.payload
            const finalData = Array.isArray(data)
                ? data.map((q) => ({
                      value: q?.club_membership_plans_id,
                      label: q?.plan_name
                  }))
                : []
            state.data = finalData
            state.loading = false
        })
        builder.addCase(SweepstakeVarietyCreate.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(SweepstakeVarietyById.pending, (state) => {
            state.loading = false
        })
        builder.addCase(SweepstakeVarietyById.fulfilled, (state, action) => {
            const { data } = action.payload
            state.form = data
            state.loading = false
        })
        builder.addCase(SweepstakeVarietyById.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default SweepstakeVariety.reducer
