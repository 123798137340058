import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const SanctionFeesConfigListApi = (data) =>
    API.get(`${URL.GET_ALL_SANCTION_FEES_CONFIG_URL}`, data)

export const SanctionFeesConfigByIdApi = (ID) =>
    API.get(`${URL.GET_ONE_SANCTION_FEES_CONFIG_URL}/${ID}`)

export const SanctionFeesConfigCreateApi = (data) =>
    API.post(`${URL.CREATE_SANCTION_FEES_CONFIG_URL}`, data)

export const SanctionFeesConfigUpdateApi = (ID, data) =>
    API.put(`${URL.UPDATE_SANCTION_FEES_CONFIG_URL}/${ID}`, data)

export const SanctionFeesConfigDeleteApi = (ID) =>
    API.del(`${URL.DELETE_SANCTION_FEES_CONFIG_URL}/${ID}`)
