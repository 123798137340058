import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const MemberFeesConfigListApi = (data) =>
    API.get(`${URL.GET_ALL_MEMBER_FEES_CONFIG_URL}`, data)

export const MemberFeesConfigByIdApi = (ID) =>
    API.get(`${URL.GET_ONE_MEMBER_FEES_CONFIG_URL}/${ID}`)

export const MemberFeesConfigCreateApi = (data) =>
    API.post(`${URL.CREATE_MEMBER_FEES_CONFIG_URL}`, data)

export const MemberFeesConfigUpdateApi = (ID, data) =>
    API.put(`${URL.UPDATE_MEMBER_FEES_CONFIG_URL}/${ID}`, data)

export const MemberFeesConfigDeleteApi = (ID) =>
    API.del(`${URL.DELETE_MEMBER_FEES_CONFIG_URL}/${ID}`)
