import { connect } from 'react-redux'

import { Link } from 'react-router-dom'

// Reactstrap
import { Dropdown } from 'reactstrap'

// Import menuDropdown
// import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu'

// import logoSm from '../../assets/images/logo-sm.png'
// import logoDark from '../../assets/images/logo-dark.png'
// import logoLight from '../../assets/images/logo-light.png'
import ClubLogo from '../../assets/images/club-logo.png'
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu'

// Redux Store
// import // showRightSidebarAction,
// toggleLeftmenu,
// changeSidebarType,
// "../../store/actions"
const headerStyle = {
    left: 0
}
const Header = (props) => {
    const { unAuth } = props
    function toggleFullscreen() {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
            }
        } else if (document.cancelFullScreen) {
            document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen()
        }
    }

    function tToggle() {
        const { body } = document
        const windowSize = document.documentElement.clientWidth

        body.classList.toggle('vertical-collpsed')
        body.classList.toggle('sidebar-enable')
        if (windowSize > 991) {
            body.getAttribute('data-sidebar-size') === 'sm' && windowSize > 991
                ? body.setAttribute('data-sidebar-size', 'lg')
                : body.setAttribute('data-sidebar-size', 'sm')
        }
    }

    return (
        // <header id='page-topbar' style={unAuth ? headerStyle : null}>
        <header id='page-topbar' style={unAuth ? headerStyle : null}>
            <div className='navbar-header header-box'>
                <div className='d-flex align-items-center'>
                    <div className='navbar-brand-box' >
                        <Link to='/' className='logo logo-dark'>
                            <span className='logo-sm'>
                                <img src={ClubLogo} alt='' height='30' />
                            </span>
                            <span className='logo-lg'>
                                <img src={ClubLogo} alt='' height='30' />
                            </span>
                        </Link>

                        <Link to='/' className='logo logo-light'>
                            <span className='logo-sm'>
                                <img src={ClubLogo} alt='' height='30' />
                            </span>
                            <span className='logo-lg'>
                                <img src={ClubLogo} alt='' height='30' />
                            </span>
                        </Link>
                    </div>

                    <button
                        type='button'
                        onClick={() => {
                            tToggle()
                        }}
                        className='btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn'
                        id='vertical-menu-btn'
                    >
                        <i className='fa fa-fw fa-bars' />
                    </button>
                    {unAuth ? (
                      <img src={ClubLogo} alt='' height='' />
                    ) :  <h4 className='text-white mb-0'>CLUB MANAGEMENT</h4>}
                     {/* <h4 className='text-white mb-0 ms-2'>EasyFides</h4> */}
                </div>
                {!unAuth ? (
                    <div className='d-flex'>
                        <Dropdown className='d-none d-lg-inline-block ms-1'>
                            <button
                                type='button'
                                onClick={() => {
                                    toggleFullscreen()
                                }}
                                className='btn header-item noti-icon waves-effect'
                                data-toggle='fullscreen'
                            >
                                <i className='bx bx-fullscreen mt-2' />
                            </button>
                        </Dropdown>

                        <ProfileMenu />
                    </div>
                ) : null}
            </div>
        </header>
    )
}

Header.propTypes = {
    // changeSidebarType: PropTypes.func,
    // leftMenu: PropTypes.any,
    // leftSideBarType: PropTypes.any,
    // showRightSidebar: PropTypes.any,
    // showRightSidebarAction: PropTypes.func,
    // t: PropTypes.any,
    // toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = (state) => {
    const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout
    return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
    // showRightSidebarAction,
    // toggleLeftmenu,
    // changeSidebarType,
})(Header)
