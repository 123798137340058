import { createSlice } from '@reduxjs/toolkit'
import { ClubById, ClubCreate, ClubGrandTotal, ClubList, ClubPublish, ClubUpdate } from './thunk'
import _ from 'lodash'

export const clubForm = {
    club_name: '',
    email: '',
    password: '',
    publish: true,
    verified: 0
}
export const initialState = {
    form: clubForm,
    allClubList: [],
    grandTotal: {},
    loading: true
}
const Club = createSlice({
    name: 'Club',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(ClubList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(ClubList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.form = clubForm
            state.allClubList = data
            state.loading = false
        })
        builder.addCase(ClubList.rejected, (state) => {
            state.loading = false
        })

        // Create
        builder.addCase(ClubCreate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(ClubCreate.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(ClubCreate.rejected, (state) => {
            state.loading = false
        })
        // Create
        builder.addCase(ClubGrandTotal.pending, (state) => {
            state.loading = false
        })
        builder.addCase(ClubGrandTotal.fulfilled, (state, action) => {
            const { data } = action.payload
            state.grandTotal = _.first(data)
            state.loading = false
        })
        builder.addCase(ClubGrandTotal.rejected, (state) => {
            state.loading = false
        })

        // Update
        builder.addCase(ClubUpdate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(ClubUpdate.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(ClubUpdate.rejected, (state) => {
            state.loading = false
        })
        // publish
        builder.addCase(ClubPublish.pending, (state) => {
            state.loading = true
        })
        builder.addCase(ClubPublish.fulfilled, (state) => {
            state.loading = true
        })
        builder.addCase(ClubPublish.rejected, (state) => {
            state.loading = false
        })

        // Category By Id
        builder.addCase(ClubById.pending, (state) => {
            state.loading = false
        })
        builder.addCase(ClubById.fulfilled, (state, action) => {
            let { payload } = action
            payload = {
                data: {
                    ...payload.data,
                    password: payload.data.plain_password
                }
            }

            const data = _.pick(payload?.data, Object.keys(clubForm))
            state.form = data
            state.loading = false
        })
        builder.addCase(ClubById.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = Club.actions
export default Club.reducer
