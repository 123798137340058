import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

export const SanctionProcessFeesTemplateCreateApi = (data) =>
    API.post(`${URL.SANCTION_PROCESSING_FEES_CREATE_URL}`, data)

export const SanctionProcessFeesTemplateUpdateApi = (ID, data) =>
    API.put(`${URL.SANCTION_PROCESSING_FEES_UPDATE_URL}/${ID}`, data)

//
export const SanctionProcessFeesTemplateListApi = (data) =>
    API.get(`${URL.SANCTION_PROCESSING_FEES_LIST_URL}`, data)
