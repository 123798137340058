import PropTypes from 'prop-types'

import { Route, Routes } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'

// Import Routes all
import { userRoutes, PublicRoute, clubRoutes } from './routes/allRoutes'

// Import all middleware
import AuthGuard from './routes/middleware/AuthGuard'

// layouts Format
import VerticalLayout from './components/VerticalLayout'
import NonAuthLayout from './components/NonAuthLayout'

//
import { withOneTabEnforcer } from 'react-one-tab-enforcer'
import DifferentWarningComponent from './pages/DifferentWarningComponent'

// Import scss
import './assets/scss/theme.scss'
import { Suspense, useEffect } from 'react'
import { Loader } from './Atoms'
import { userLoad } from './store/actions'

const App = (props) => {
    const dispatch = useDispatch()
    const { loading, admin, isAuth } = useSelector((state) => state.Login)

    useEffect(() => {
        dispatch(userLoad())
    }, [])
    function getLayout() {
        let layoutCls = VerticalLayout

        switch (props.layout.layoutType) {
            default:
                layoutCls = VerticalLayout
                break
        }
        return layoutCls
    }

    const Layout = getLayout()
    if (loading) {
        return (
            <div
                style={{ height: '100vh' }}
                className='d-flex  justify-content-center align-items-center'
            >
                <Loader color='primary' />
            </div>
        )
    }
    return (
        <Routes>
            {!loading && isAuth && admin?.user_role === 'club_admin'
                ? userRoutes.map((route, index) => (
                      <Route
                          path={route.path}
                          element={
                              <AuthGuard>
                                  <Layout>
                                      <Suspense
                                          fallback={
                                              <div
                                                  style={{ height: '100vh' }}
                                                  className='d-flex  justify-content-center align-items-center'
                                              >
                                                  <Loader color='primary' />
                                              </div>
                                          }
                                      >
                                          {route.component}
                                      </Suspense>
                                  </Layout>
                              </AuthGuard>
                          }
                          key={index}
                          isAuthProtected
                          exact
                      />
                  ))
                : null}
            {!loading && isAuth && admin?.role === 'club'
                ? clubRoutes.map((route, index) => (
                      <Route
                          path={route.path}
                          element={
                              <AuthGuard>
                                  <Layout>
                                      <Suspense
                                          fallback={
                                              <div
                                                  style={{ height: '100vh' }}
                                                  className='d-flex  justify-content-center align-items-center'
                                              >
                                                  <Loader color='primary' />
                                              </div>
                                          }
                                      >
                                          {route.component}
                                      </Suspense>
                                  </Layout>
                              </AuthGuard>
                          }
                          key={index}
                          isAuthProtected
                          exact
                      />
                  ))
                : null}
            {PublicRoute.map((route, index) => (
                <Route
                    path={route.path}
                    element={
                        <Suspense
                            fallback={
                                <div
                                    style={{ height: '100vh' }}
                                    className='d-flex  justify-content-center align-items-center'
                                >
                                    <Loader color='primary' />
                                </div>
                            }
                        >
                            <NonAuthLayout>{route.component}</NonAuthLayout>
                        </Suspense>
                    }
                    key={index}
                    isAuthProtected={false}
                />
            ))}
        </Routes>
    )
}

App.propTypes = {
    layout: PropTypes.any
}

const mapStateToProps = (state) => ({
    layout: state.Layout
})

export default withOneTabEnforcer({
    appName: 'Admin-Panel-Club-software',
    localStorageResetInterval: 10 * 1000,
    localStorageTimeout: 10 * 1000,
    OnlyOneTabComponent: DifferentWarningComponent
})(connect(mapStateToProps, null)(App))
