import { createSlice } from '@reduxjs/toolkit'
import { ClubUserById, ClubUserCreate, ClubUserList } from './thunk'
import _ from 'lodash'

export const clubUserForm = {
    first_name: '',
    last_name: '',
    user_email: '',
    password: '',
    confirm_password: '',
    address_1: '',
    address_2: '',
    country: '',
    state: '',
    city: '',
    zip_code: '',
    phone: '',
    arba_no: '',
    is_no_email: false
}
export const initialState = {
    data: [],
    form: clubUserForm,
    loading: true
}
const ClubUser = createSlice({
    name: 'clubUser',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(ClubUserList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(ClubUserList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.form = clubUserForm
            state.data = data
            state.loading = false
        })
        builder.addCase(ClubUserList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(ClubUserCreate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(ClubUserCreate.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(ClubUserCreate.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(ClubUserById.pending, (state) => {
            state.loading = false
        })
        builder.addCase(ClubUserById.fulfilled, (state, action) => {
            const { payload } = action

            const data = _.pick(payload?.data, Object.keys(clubUserForm))
            state.form = data
            state.loading = false
        })
        builder.addCase(ClubUserById.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default ClubUser.reducer
