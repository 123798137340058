import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const SetupFeeListApi = (data) => API.get(`${URL.SETUP_FEE_LIST_URL}`, data)
export const SetupFeeCreateApi = (data) => API.post(`${URL.SETUP_FEE_CREATE_URL}`, data)
export const SetupFeeByIdApi = (ID) => API.get(`${URL.SETUP_FEE_ONE_URL}/${ID}`)
export const SetupFeeUpdateApi = (ID, data) => API.put(`${URL.SETUP_FEE_UPDATE_URL}/${ID}`, data)
export const SetupFeeDeleteApi = (ID) => API.del(`${URL.SETUP_FEE_DELETE_URL}/${ID}`)
