import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    SetupFeeByIdApi,
    SetupFeeCreateApi,
    SetupFeeDeleteApi,
    SetupFeeListApi,
    SetupFeeUpdateApi
} from './setupFee.services'
import { paginationData } from '../Pagination/reducers'

//
export const SetupFeeList = createAsyncThunk(
    'SetupFee/List',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await SetupFeeListApi(data)

            if (data._limit) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data._page,
                    totalPage: TotalPage,
                    totalRecords: res?.totalRecords
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//

export const SetupFeeCreate = createAsyncThunk(
    'SetupFee/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SetupFeeCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//

export const SetupFeeUpdate = createAsyncThunk(
    'SetupFee/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SetupFeeUpdateApi(data.ID, data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const SetupFeeById = createAsyncThunk(
    'SetupFee/getById',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await SetupFeeByIdApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const SetupFeeDelete = createAsyncThunk(
    'SetupFee/delete',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await SetupFeeDeleteApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
