import { createSlice } from '@reduxjs/toolkit'
import {
    clubLoad,
    userChangePassword,
    userLoad,
    userLogin,
    userLogout,
    userProfileUpdate,
    userRefreshToken
} from './thunk'

export const loginForm = {
    email: '',
    password: '',
    role: 'club_admin'
}
export const initialState = {
    token: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    loginForm,
    admin: {},
    loading: true,
    isAuth: false
}

const loginSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        AuthFail: (state) => {
            state.loading = false
            localStorage.clear()
            state.token = null
            state.refreshToken = null
            state.admin = {}
            state.loading = false
            state.isAuth = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(userLogin.pending, (state) => {
            state.loading = false
        })
        builder.addCase(userLogin.fulfilled, (state, action) => {
            const { accessToken, refreshToken, data } = action.payload
            localStorage.setItem('accessToken', accessToken)
            localStorage.setItem('refreshToken', refreshToken)
            localStorage.setItem('user', JSON.stringify(data))
            state.token = accessToken
            state.refreshToken = refreshToken
            state.admin = data
            state.loading = false
            state.isAuth = true
        })
        builder.addCase(userLogin.rejected, (state) => {
            state.token = null
            state.refreshToken = null
            state.admin = {}
            state.loading = false
            state.isAuth = false
        })

        builder.addCase(userLoad.pending, (state) => {
            state.loading = true
        })
        builder.addCase(userLoad.fulfilled, (state, action) => {
            const { data } = action.payload
            state.admin = data
            state.loading = false
            state.isAuth = true
        })
        builder.addCase(userLoad.rejected, () => {
            // localStorage.clear()
            // state.token = null
            // state.refreshToken = null
            // state.admin = {}
            // state.loading = false
            // state.isAuth = false
        })
        builder.addCase(clubLoad.pending, (state) => {
            state.loading = true
        })
        builder.addCase(clubLoad.fulfilled, (state, action) => {
            const { data } = action.payload
            state.admin = data
            state.loading = false
            state.isAuth = true
        })
        builder.addCase(clubLoad.rejected, () => {
            // localStorage.clear()
            // state.token = null
            // state.refreshToken = null
            // state.admin = {}
            // state.loading = false
            // state.isAuth = false
        })
        // Logout
        builder.addCase(userLogout.pending, (state) => {
            state.loading = false
        })
        builder.addCase(userLogout.fulfilled, (state) => {
            localStorage.clear()
            if (state?.admin?.role === 'club') {
                window.location.replace(`${process.env.REACT_APP_FRONT_URL}/login?logout=true`)
            }
            state.admin = {}
            state.token = null
            state.token = null
            state.loading = false
            state.isAuth = false
        })

        builder.addCase(userLogout.rejected, (state) => {
            //  Remove this code
            localStorage.clear()
            if (state?.admin?.role === 'club') {
                window.location.replace(`${process.env.REACT_APP_FRONT_URL}/login?logout=true`)
            }
            state.admin = {}
            state.token = null
            state.token = null
            state.loading = false
            state.isAuth = false
        })
        builder.addCase(userProfileUpdate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(userProfileUpdate.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(userProfileUpdate.rejected, (state) => {
            state.loading = false
        })

        // Changes Password

        builder.addCase(userChangePassword.pending, (state) => {
            state.loading = false
        })
        builder.addCase(userChangePassword.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(userChangePassword.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(userRefreshToken.pending, (state) => {
            state.loading = true
        })
        builder.addCase(userRefreshToken.fulfilled, (state, action) => {
            window.location.reload(true)

            state.loading = false
            const { accessToken } = action.payload
            localStorage.setItem('accessToken', accessToken)
            state.token = accessToken
        })
        builder.addCase(userRefreshToken.rejected, (state) => {
            state.loading = false
        })
    }
})

export const { AuthFail } = loginSlice.actions
export default loginSlice.reducer
