import { get, patch, post } from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

export const userLoginApi = (data) => post(URL.USER_LOGIN_URL, data)
export const userRefreshTokenApi = (data) => post(URL.REFRESH_TOKEN_URL, data)
export const userLoadApi = (data) => get(URL.USER_LOAD_URL, data)
export const clubLoadApi = (data) => get(URL.CLUB_LOAD_URL, data)
export const userLogoutApi = (data) => post(URL.USER_LOGOUT_URL, data)
export const userProfileUpdateApi = (data) => patch(URL.USER_PROFILE_UPDATE_URL, data)
export const userChangePasswordApi = (data) => patch(URL.USER_PASSWORD_CHANGE_URL, data)
export const userForgotPasswordApi = (data) => post(URL.USER_FORGOT_PASSWORD_URL, data)
