import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

export const MembershipProcessFeesCreateApi = (data) =>
    API.post(`${URL.MEMBERSHIP_PROCESSING_FEES_CREATE_URL}`, data)

export const MembershipProcessFeesUpdateApi = (ID, data) =>
    API.put(`${URL.MEMBERSHIP_PROCESSING_FEES_UPDATE_URL}/${ID}`, data)

//
export const MembershipProcessFeesListApi = (data) =>
    API.get(`${URL.MEMBERSHIP_PROCESSING_FEES_LIST_URL}`, data)
