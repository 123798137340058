import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    SanctionProcessFeesTemplateCreateApi,
    SanctionProcessFeesTemplateListApi,
    SanctionProcessFeesTemplateUpdateApi
} from './SanctionProcessingFees.services'

export const SanctionProcessFeeCreate = createAsyncThunk(
    '/Sanction/process/fees/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionProcessFeesTemplateCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SanctionProcessFeeUpdate = createAsyncThunk(
    '/Sanction/process/fees/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionProcessFeesTemplateUpdateApi(
                data.sanction_profit_percentage_id,
                data
            )
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SanctionProcessFeeList = createAsyncThunk(
    '/Sanction/process/fees/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionProcessFeesTemplateListApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
