import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const MembershipRevenueBreakDownApi = (data) => {
    return API.post(`${URL.GET_ALL_REVENUE_BREAK_DOWN_LIST}`, data.body, {
        params: data.params
    })
}

export const ClubListForRevenueBreakDownApi = (data) =>
    API.post(`${URL.GET_ALL_REVENUE_BREAK_DOWN_CLUB_LIST}`, data)
export const UserListByClubForRevenueBreakDownApi = (data) =>
    API.post(`${URL.GET_ALL_REVENUE_BREAK_DOWN_USER_LIST_BY_CLUB}`, data.body)
export const RevenueBreakDownGrandTotalApi = (data) =>
    API.get(`${URL.REVENUE_BREAK_DOWN_GRAND_TOTAL}`, data?.params)
