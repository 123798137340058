import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    MembershipProcessFeesCreateApi,
    MembershipProcessFeesListApi,
    MembershipProcessFeesUpdateApi
} from './MembershipProcessingFees.services'

export const MemberShipProcessFeeCreate = createAsyncThunk(
    '/process/fees/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipProcessFeesCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MemberShipProcessFeeUpdate = createAsyncThunk(
    '/process/fees/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipProcessFeesUpdateApi(
                data.membership_profit_percentage_id,
                data
            )
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const MemberShipProcessFeeList = createAsyncThunk(
    '/process/fees/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipProcessFeesListApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
