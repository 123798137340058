import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    clubLoadApi,
    userChangePasswordApi,
    userForgotPasswordApi,
    userLoadApi,
    userLoginApi,
    userLogoutApi,
    userProfileUpdateApi,
    userRefreshTokenApi
} from './Auth.services'

export const userLogin = createAsyncThunk('user/login', async (data, { rejectWithValue }) => {
    try {
        const res = await userLoginApi(data)

        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

//
export const userLoad = createAsyncThunk('user/load', async (data, { rejectWithValue }) => {
    try {
        const res = await userLoadApi()

        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})
export const clubLoad = createAsyncThunk('club/load', async (data, { rejectWithValue }) => {
    try {
        const res = await clubLoadApi()

        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

//
export const userLogout = createAsyncThunk('user/logout', async (data, { rejectWithValue }) => {
    try {
        const res = await userLogoutApi(data)
        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

//
export const userProfileUpdate = createAsyncThunk(
    'user/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await userProfileUpdateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const userChangePassword = createAsyncThunk(
    'user/changePassword',
    async (data, { rejectWithValue }) => {
        try {
            const res = await userChangePasswordApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const userForgotPassword = createAsyncThunk(
    'user/forgot-password',
    async (data, { rejectWithValue }) => {
        try {
            const res = await userForgotPasswordApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const userRefreshToken = createAsyncThunk(
    'user/forgot-password',
    async (data, { rejectWithValue }) => {
        try {
            const res = await userRefreshTokenApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
