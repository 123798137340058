import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    SanctionFeesConfigListApi,
    SanctionFeesConfigByIdApi,
    SanctionFeesConfigCreateApi,
    SanctionFeesConfigDeleteApi,
    SanctionFeesConfigUpdateApi
} from './SanctionFeesConfig.services'
import { paginationData } from '../Pagination/reducers'

//services
export const SanctionFeesConfigList = createAsyncThunk(
    'SanctionFeesConfig/list',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await SanctionFeesConfigListApi(data)

            if (data._limit) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data._page,
                    totalPage: TotalPage,
                    totalRecords: res?.totalRecords
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const SanctionFeesConfigCreate = createAsyncThunk(
    'SanctionFeesConfig/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionFeesConfigCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const SanctionFeesConfigUpdate = createAsyncThunk(
    'SanctionFeesConfig/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SanctionFeesConfigUpdateApi(data.fee_id, data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const SanctionFeesConfigById = createAsyncThunk(
    'SanctionFeesConfig/one',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await SanctionFeesConfigByIdApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const SanctionFeesConfigDelete = createAsyncThunk(
    'SanctionFeesConfig/delete',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await SanctionFeesConfigDeleteApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
