/* eslint-disable no-debugger */
import { useCallback, useEffect, useRef } from 'react'

// //Import Scrollbar
import SimpleBar from 'simplebar-react'

// MetisMenu
import MetisMenu from 'metismenujs'
import { Link, useLocation } from 'react-router-dom'

const SidebarContent = () => {
    const ref = useRef()
    const activateParentDropdown = useCallback((item) => {
        item.classList.add('active')
        const parent = item.parentElement
        const parent2El = parent.childNodes[1]

        if (parent2El && parent2El.id !== 'side-menu') {
            parent2El.classList.add('mm-show')
        }

        if (parent) {
            parent.classList.add('mm-active')
            const parent2 = parent.parentElement

            if (parent2) {
                parent2.classList.add('mm-show') // ul tag

                const parent3 = parent2.parentElement // li tag

                if (parent3) {
                    parent3.classList.add('mm-active') // li
                    parent3.childNodes[0].classList.add('mm-active') //a
                    const parent4 = parent3.parentElement // ul
                    if (parent4) {
                        parent4.classList.add('mm-show') // ul
                        const parent5 = parent4.parentElement
                        if (parent5) {
                            parent5.classList.add('mm-show') // li
                            parent5.childNodes[0].classList.add('mm-active') // a tag
                        }
                    }
                }
            }
            scrollElement(item)
            return false
        }
        scrollElement(item)
        return false
    }, [])

    const removeActivation = (items) => {
        for (let i = 0; i < items.length; ++i) {
            const item = items[i]
            const parent = items[i].parentElement

            if (item && item.classList.contains('active')) {
                item.classList.remove('active')
            }
            if (parent) {
                const parent2El =
                    parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
                        ? parent.childNodes[1]
                        : null
                if (parent2El && parent2El.id !== 'side-menu') {
                    parent2El.classList.remove('mm-show')
                }

                parent.classList.remove('mm-active')
                const parent2 = parent.parentElement

                if (parent2) {
                    parent2.classList.remove('mm-show')

                    const parent3 = parent2.parentElement
                    if (parent3) {
                        parent3.classList.remove('mm-active') // li
                        parent3.childNodes[0].classList.remove('mm-active')

                        const parent4 = parent3.parentElement // ul
                        if (parent4) {
                            parent4.classList.remove('mm-show') // ul
                            const parent5 = parent4.parentElement
                            if (parent5) {
                                parent5.classList.remove('mm-show') // li
                                parent5.childNodes[0].classList.remove('mm-active') // a tag
                            }
                        }
                    }
                }
            }
        }
    }

    const path = useLocation()
    const activeMenu = useCallback(() => {
        let pathName = path.pathname.split('/')

        pathName = `/${pathName[1]}${pathName[2] ? `/${pathName[2]}` : ''}`
        let matchingMenuItem = null
        const ul = document.getElementById('side-menu')
        const items = ul.getElementsByTagName('a')
        removeActivation(items)

        for (let i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
                matchingMenuItem = items[i]
                break
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem)
        }
    }, [path.pathname, activateParentDropdown])

    useEffect(() => {
        ref.current.recalculate()
    }, [])

    useEffect(() => {
        new MetisMenu('#side-menu')
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        activeMenu()
    }, [activeMenu])

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300
            }
        }
    }
    return (
        <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
            <div id='sidebar-menu'>
                <ul className='metismenu list-unstyled' id='side-menu'>
                    <li>
                        <Link to='/dashboard' className='waves-effect m-0'>
                            <i className='bx bx-home-alt fs-box' />
                            <span>Dashboard</span>
                        </Link>
                    </li>

                    <li>
                        <Link
                            to='/#'
                            className='has-arrow waves-effect m-0'
                            title='Club Management'
                        >
                            <i className='bx bx bxs-id-card fs-box' />
                            <span>Club Management</span>
                        </Link>
                        <ul className='sub-menu'>
                            <li>
                                <Link to='/dashboard/club-list' className='waves-effect m-0'>
                                    <i className='bx bx-list-ol fs-box' />
                                    <span>Club List</span>
                                </Link>
                            </li>
                            <li>
                                <Link to='/dashboard/set-up-fee' className='waves-effect m-0'>
                                    <i className='bx bx-cog fs-box' />
                                    <span>Setup Fees</span>
                                </Link>
                            </li>
                            {/* <li>
                                <Link to='/dashboard/processing_fees' className='waves-effect m-0'>
                                    <i className='bx bx-dollar fs-box' />
                                    <span>Membership Profit Percentage</span>
                                </Link>
                            </li> */}
                            {/* <li>
                                <Link to='/dashboard/annual-renew-fee' className='waves-effect m-0'>
                                    <span>Annual renew fee</span>
                                </Link>
                            </li> */}
                        </ul>
                    </li>
                    <li>
                        <Link to='/#' className='has-arrow  waves-effect m-0' title='Member'>
                            {/* <i class='bx bxs-credit-card-front'></i> */}
                            <i className='bx bx-credit-card fs-box' />
                            <span>Member</span>
                        </Link>
                        <ul className='sub-menu member-sub'>
                            <li>
                                {/* <Link
                                    to='/dashboard/sanction_processing_fees'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-money' />
                                    <span>Sanction Processing Fees</span>
                                </Link> */}
                                {/* <Link
                                    to='/dashboard/membership'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-group fs-box' />
                                    <span>Member List</span>
                                </Link> */}
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/member-fees-config'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-receipt' />
                                    <span>Member Fees Config</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/membership-revenue-breakdown'
                                    className='waves-effect m-0 gr-active'
                                >
                                    {' '}
                                    <i className='bx bx-money' />
                                    <span>Revenue Breakdown</span>
                                </Link>
                            </li>
                        </ul>
                        {/* <Link to='/dashboard/membership' className='waves-effect m-0'>
                            <i className='bx bx-group fs-box' />
                            <span>Member List</span>
                        </Link> */}
                    </li>

                    <li>
                        <Link to='/#' className='has-arrow  waves-effect m-0' title='Sanction'>
                            {/* <i class='bx bxs-credit-card-front'></i> */}
                            <i className='bx bx-credit-card fs-box' />
                            <span>Sanction</span>
                        </Link>
                        <ul className='sub-menu member-sub'>
                            {/* <li>
                                <Link
                                    to='/dashboard/sanction_processing_fees'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-money' />
                                    <span>Sanction Processing Fees</span>
                                </Link>
                            </li> */}
                            <li>
                                {/* <Link
                                    to='/dashboard/sanction_processing_fees'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-money' />
                                    <span>Sanction Processing Fees</span>
                                </Link> */}
                                <Link
                                    to='/dashboard/sanction-fees-config'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-receipt' />
                                    <span>Sanction Fees Config</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </SimpleBar>
    )
}

SidebarContent.propTypes = {}

export default SidebarContent
