import { createSlice } from '@reduxjs/toolkit'
import { MemberFeesConfigById, MemberFeesConfigList } from './thunk'
import _ from 'lodash'

export const feesConfigForm = {
    fee_id: '',
    fee_from: '',
    fee_to: '',
    fee_amount: ''
}
export const initialState = {
    data: [],
    form: feesConfigForm,
    loading: true
}
const MemberFeesConfig = createSlice({
    name: 'MemberFeesConfig',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(MemberFeesConfigList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(MemberFeesConfigList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.form = feesConfigForm
            state.data = data
            state.loading = false
        })
        builder.addCase(MemberFeesConfigList.rejected, (state) => {
            state.loading = false
        })

        builder.addCase(MemberFeesConfigById.pending, (state) => {
            state.loading = true
        })
        builder.addCase(MemberFeesConfigById.fulfilled, (state, action) => {
            const { payload } = action

            const data = _.pick(payload?.data, Object.keys(feesConfigForm))
            state.form = data
            state.loading = false
        })
        builder.addCase(MemberFeesConfigById.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default MemberFeesConfig.reducer
