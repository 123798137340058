import { createSlice } from '@reduxjs/toolkit'
import {
    ClubListForRevenueBreakDown,
    MembershipRevenueBreakDown,
    UserListByClubForRevenueBreakDown,
    RevenueBreakDownGrandTotal
} from './thunk'

export const initialState = {
    list: [],
    clubs: {
        list: [],
        loading: true
    },
    users: {
        list: [],
        loading: false
    },
    grandTotal: {
        grand_total: '0.00',
        connected_accnt_amt: '0.00',
        master_accnt_amt: '0.00',
        loading: true
    },
    loading: true
}
const MemberShipRevenueBreakDown = createSlice({
    name: '@membership/RevenueBreakDown',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        ///
        builder.addCase(MembershipRevenueBreakDown.pending, (state) => {
            state.list = []
            state.loading = true
        })
        builder.addCase(MembershipRevenueBreakDown.fulfilled, (state, action) => {
            const { data } = action.payload
            state.list = data || []
            state.loading = false
        })
        builder.addCase(MembershipRevenueBreakDown.rejected, (state) => {
            state.list = []
            state.loading = false
        })

        //
        builder.addCase(ClubListForRevenueBreakDown.pending, (state) => {
            state.clubs.list = []
            state.clubs.loading = true
        })
        builder.addCase(ClubListForRevenueBreakDown.fulfilled, (state, action) => {
            state.clubs.list = action.payload || []
            state.clubs.loading = false
        })
        builder.addCase(ClubListForRevenueBreakDown.rejected, (state) => {
            state.clubs.list = []
            state.clubs.loading = false
        })

        //
        builder.addCase(UserListByClubForRevenueBreakDown.pending, (state) => {
            state.users.list = []
            state.users.loading = true
        })
        builder.addCase(UserListByClubForRevenueBreakDown.fulfilled, (state, action) => {
            state.users.list = action.payload || []
            state.users.loading = false
        })
        builder.addCase(UserListByClubForRevenueBreakDown.rejected, (state) => {
            state.users.list = []
            state.users.loading = false
        })
        //
        builder.addCase(RevenueBreakDownGrandTotal.pending, (state) => {
            state.grandTotal.master_accnt_amt = '0.00'
            state.grandTotal.grand_total = '0.00'
            state.grandTotal.connected_accnt_amt = '0.00'
            state.grandTotal.loading = true
        })
        builder.addCase(RevenueBreakDownGrandTotal.fulfilled, (state, { payload }) => {
            state.grandTotal.master_accnt_amt = payload.master_accnt_amt
            state.grandTotal.grand_total = payload.grand_total
            state.grandTotal.connected_accnt_amt = payload.connected_accnt_amt
            state.grandTotal.loading = false
        })
        builder.addCase(RevenueBreakDownGrandTotal.rejected, (state) => {
            state.users.list = []
            state.grandTotal.loading = false
        })
    }
})

export default MemberShipRevenueBreakDown.reducer
