import { createSlice } from '@reduxjs/toolkit'
import { SetupFeeList, SetupFeeById, SetupFeeCreate, SetupFeeUpdate } from './thunk'
import _ from 'lodash'

export const setupFeeForm = {
    plan_title: '',
    no_of_members: '',
    fee_amount: '',
    renew_fee_amount: '',
    display_order: null,
}
export const initialState = {
    form: setupFeeForm,
    SetupFees: [],
    loading: true
}
const SetupFee = createSlice({
    name: 'setupFee',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(SetupFeeList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(SetupFeeList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.form = setupFeeForm
            state.SetupFees = data
            state.loading = false
        })
        builder.addCase(SetupFeeList.rejected, (state) => {
            state.loading = false
        })

        // Create
        builder.addCase(SetupFeeCreate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(SetupFeeCreate.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(SetupFeeCreate.rejected, (state) => {
            state.loading = false
        })

        // Update
        builder.addCase(SetupFeeUpdate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(SetupFeeUpdate.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(SetupFeeUpdate.rejected, (state) => {
            state.loading = false
        })

        // Category By Id
        builder.addCase(SetupFeeById.pending, (state) => {
            state.loading = false
        })
        builder.addCase(SetupFeeById.fulfilled, (state, action) => {
            const { payload } = action

            const data = _.pick(payload.data, Object.keys(setupFeeForm))
            state.form = data
            state.loading = false
        })
        builder.addCase(SetupFeeById.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default SetupFee.reducer
