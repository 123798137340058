import { createSlice } from '@reduxjs/toolkit'
import { SanctionProcessFeeList } from './thunk'
import _ from 'lodash'
// import _ from 'lodash'

export const form = {
    sanction_profit_percentage_id: '',
    processing_percentage: ''
}

export const initialState = {
    loading: true,
    form
}
const SanctionProcessingFees = createSlice({
    name: 'Sanction Processing',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(SanctionProcessFeeList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(SanctionProcessFeeList.fulfilled, (state, action) => {
            const { data } = action.payload
            let formData = _.first(data)
            if (formData) {
                formData = {
                    processing_percentage: formData?.processing_percentage || '',
                    sanction_profit_percentage_id: formData?.sanction_profit_percentage_id || ''
                }
            }

            state.form = formData ? formData : form
            state.loading = false
        })
        builder.addCase(SanctionProcessFeeList.rejected, (state) => {
            state.loading = false
        })
    }
})

export default SanctionProcessingFees.reducer
