import { createSlice } from '@reduxjs/toolkit'
import { MemberShipProcessFeeList } from './thunk'
import _ from 'lodash'
// import _ from 'lodash'

export const form = {
    membership_profit_percentage_id: '',
    processing_percentage: ''
}

export const initialState = {
    loading: true,
    form
}
const MembershipProcessingFees = createSlice({
    name: 'ProcessingFee',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(MemberShipProcessFeeList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(MemberShipProcessFeeList.fulfilled, (state, action) => {
            const { data } = action.payload
            let formData = _.first(data)
            if (formData) {
                formData = {
                    processing_percentage: formData?.processing_percentage || '',
                    membership_profit_percentage_id: formData?.membership_profit_percentage_id || ''
                }
            }

            state.form = formData ? formData : form
            state.loading = false
        })
        builder.addCase(MemberShipProcessFeeList.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default MembershipProcessingFees.reducer
