import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const SweepstakeVarietyListApi = (data) => API.get(`${URL.SWEEPSTAKE_VARIETY_LIST_LIST}`, data)
export const SweepstakeVarietyCreateApi = (data) => API.post(`${URL.SWEEPSTAKE_VARIETY_LIST_CREATE}`, data)
export const SweepstakeVarietyByIdApi = (Id) => API.get(`${URL.SWEEPSTAKE_VARIETY_LIST_ONE}/${Id}`)
export const SweepstakeVarietyUpdateApi = (Id, data) =>
    API.put(`${URL.SWEEPSTAKE_VARIETY_LIST_UPDATE}/${Id}`, data)
export const SweepstakeVarietyDeleteApi = (Id) => API.del(`${URL.SWEEPSTAKE_VARIETY_LIST_DELETE}/${Id}`)
export const SweepstakeVarietyStatusApi = (Id, data) =>
    API.put(`${URL.SWEEPSTAKE_VARIETY_LIST_PUBLISH_STATUS}/${Id}`, data)

//MEMBERSHIP_PLANS_LIST_FOR_MEMBER_CREATE
// export const SweepstakeVarietyListForMemberCreateApi = (data) =>
//     API.get(`${URL.MEMBERSHIP_PLANS_LIST_FOR_MEMBER_CREATE}`, data)
