import { createSlice } from '@reduxjs/toolkit'
import { AllSubMemberList, ClubSubUserById, ClubSubUserCreate, ClubSubUserList } from './thunk'
import _ from 'lodash'

export const clubSubUserForm = {
    parent_name: '',
    su_first_name: '',
    su_last_name: '',
    arba_no: '',
    is_minor: true,
    dob: '',
    status: false
}

export const initialState = {
    data:[],
    form: clubSubUserForm,
    loading: true
}
const ClubSubUser = createSlice({
    name: 'clubSubUser',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Listing
        builder.addCase(AllSubMemberList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(AllSubMemberList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.form = clubSubUserForm
            state.data = data
            state.loading = false
        })
        builder.addCase(AllSubMemberList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(ClubSubUserList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(ClubSubUserList.fulfilled, (state, action) => {
            const { data } = action.payload
            state.form = clubSubUserForm
            state.data = data
            state.loading = false
        })
        builder.addCase(ClubSubUserList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(ClubSubUserCreate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(ClubSubUserCreate.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(ClubSubUserCreate.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(ClubSubUserById.pending, (state) => {
            state.loading = false
        })
        builder.addCase(ClubSubUserById.fulfilled, (state, action) => {
            const { payload } = action

            const data = _.pick(payload?.data, Object.keys(clubSubUserForm))
            state.form = data
            state.loading = false
        })
        builder.addCase(ClubSubUserById.rejected, (state) => {
            state.loading = false
        })
    }
})

// export const {} = SetupFee.actions
export default ClubSubUser.reducer
