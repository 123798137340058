import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const ClubSubUserListApi = (data) => API.get(`${URL.CLUB_PLANS_LIST}`, data)

export const ClubSubUserByIdApi = (ID) => API.get(`${URL.CLUB_PLANS_LIST}/${ID}`)

export const ClubSubUserCreateApi = (data) => API.post(`${URL.CLUB_PLANS_LIST}`,data)

export const ClubSubUserUpdateApi = (ID,data) => API.put(`${URL.CLUB_PLANS_LIST}/${ID}`,data)

export const ClubSubUserDeleteApi = (ID) =>API.del(`${URL.CLUB_PLANS_LIST}/${ID}`)

export const AllSubMemberListApi = (data) => API.get(`${URL.CLUB_PLANS_LIST}`, data)