import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const ClubListApi = (data) => API.get(`${URL.CLUB_LIST_URL}`, data)
export const ClubCreateApi = (data) => API.post(`${URL.CLUB_CREATE_URL}`, data)
export const ClubByIdApi = (ID) => API.get(`${URL.CLUB_ONE_URL}/${ID}`)
export const ClubGrandTotalApi = (ID) => API.get(`${URL.CLUB_GRAND_TOTAL_URL}/${ID}`)
export const ClubUpdateApi = (ID, data) => API.put(`${URL.CLUB_UPDATE_URL}/${ID}`, data)
export const ClubDeleteApi = (ID) => API.del(`${URL.CLUB_DELETE_URL}/${ID}`)
export const ClubPublishApi = (ID, data) => API.patch(`${URL.CLUB_PUBLISH_URL}/${ID}`, data)
