import { createAsyncThunk } from '@reduxjs/toolkit'
import { paginationData } from '../Pagination/reducers'
import {
    SweepstakeVarietyByIdApi,
    SweepstakeVarietyCreateApi,
    SweepstakeVarietyDeleteApi,
    SweepstakeVarietyListApi,
    SweepstakeVarietyUpdateApi
} from './Variety.services'

//services
export const SweepstakeVarietyList = createAsyncThunk(
    'Sweepstake/variety/List',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await SweepstakeVarietyListApi(data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//

export const SweepstakeVarietyById = createAsyncThunk(
    'Sweepstake/variety/getById',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await SweepstakeVarietyByIdApi(data)

            if (Number(data?._limit || 0) > 1) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (Number(data?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SweepstakeVarietyDelete = createAsyncThunk(
    'Sweepstake/variety/delete',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SweepstakeVarietyDeleteApi(data)

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SweepstakeVarietyCreate = createAsyncThunk(
    'Sweepstake/variety/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SweepstakeVarietyCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
export const SweepstakeVarietyUpdate = createAsyncThunk(
    'Sweepstake/variety/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await SweepstakeVarietyUpdateApi(data.club_membership_plans_id, data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
