import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    MemberFeesConfigListApi,
    MemberFeesConfigByIdApi,
    MemberFeesConfigCreateApi,
    MemberFeesConfigDeleteApi,
    MemberFeesConfigUpdateApi
} from './MemberFeesConfig.services'
import { paginationData } from '../Pagination/reducers'

//services
export const MemberFeesConfigList = createAsyncThunk(
    'MemberFeesConfig/list',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await MemberFeesConfigListApi(data)

            if (data._limit) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data._page,
                    totalPage: TotalPage,
                    totalRecords: res?.totalRecords
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const MemberFeesConfigCreate = createAsyncThunk(
    'MemberFeesConfig/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MemberFeesConfigCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const MemberFeesConfigUpdate = createAsyncThunk(
    'MemberFeesConfig/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MemberFeesConfigUpdateApi(data.fee_id, data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const MemberFeesConfigById = createAsyncThunk(
    'MemberFeesConfig/one',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await MemberFeesConfigByIdApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const MemberFeesConfigDelete = createAsyncThunk(
    'MemberFeesConfig/delete',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await MemberFeesConfigDeleteApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
