import { useState } from 'react'

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
// Redux

// users
import ProfileLogo from 'src/assets/images/logo-sm.png'
import { userLogout } from 'src/store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const ProfileMenu = () => {
    const { admin } = useSelector((state) => state.Login)
    const dispatch = useDispatch()
    // Declare a new state variable, which we'll call "menu"
    const { refreshToken } = useSelector((state) => state.Login)
    const [menu, setMenu] = useState(false)

    return (
        <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className='d-inline-block'>
            <DropdownToggle
                className='btn header-item waves-effect'
                id='page-header-user-dropdown'
                tag='button'
            >
                <img
                    className='rounded-circle header-profile-user'
                    src={admin?.profile_pic ? admin?.profile_pic : ProfileLogo}
                    alt='Header Avatar'
                />
                <span className='d-none d-xl-inline-block ms-1 fw-medium font-size-14'>
                    {admin.role === 'club' ? (
                        admin?.club_name || ''
                    ) : (
                        <>
                            {admin?.last_name || ''} {admin?.first_name || ''}
                        </>
                    )}
                </span>{' '}
                <i className='bx bx-chevron-down d-none d-xl-inline-block font-size-20' />
            </DropdownToggle>
            <DropdownMenu className='dropdown-menu-end'>
                <Link to='/dashboard/profile'>
                    <DropdownItem>
                        {' '}
                        <i className='bx bx-user-circle font-size-18 align-middle text-muted me-1 hover' />
                        Profile
                    </DropdownItem>
                </Link>
                <Link to='/dashboard/change-password'>
                    <DropdownItem
                        className='dropdown-item'
                        // onClick={() => {
                        //     const newData = {
                        //         refreshToken
                        //     }
                        //     dispatch(userLogout(newData))
                        // }}
                    >
                        <i className='bx bx-lock font-size-18 align-middle me-1 text-muted hover' />
                        <span>Change Password</span>
                    </DropdownItem>
                </Link>

                <div className='dropdown-divider' />
                <DropdownItem
                    className='dropdown-item'
                    onClick={() => {
                        const newData = {
                            refreshToken
                        }
                        dispatch(userLogout(newData))
                    }}
                >
                    <i className='bx bxs-log-in-circle font-size-18 align-middle me-1 text-muted hover' />
                    <span>Logout</span>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}

ProfileMenu.propTypes = {}

export default ProfileMenu
