import { combineReducers } from 'redux'

// Front
import Layout from './layout/reducer'
import { loadingBarReducer } from 'react-redux-loading-bar'
//
import Club from './Club/reducer'
import SetupFee from './SetupFee/reducer'
import AnnualRenewFee from './AnnualRenewFee/reducer'
import Login from './Auth/reducer'
import ClubPlans from './ClubPlans/reducer'
import Pagination from './Pagination/reducers'
import ProcessingFee from './MembershipProcessingFees/reducer'
import Plan from './Plan/reducer'
import ClubUser from './ClubUser/reducer'
import ClubSubUser from './ClubSubUser/reducer'
import SweepstakeVariety from './Variety/reducer'
import SanctionProcessingFees from './SanctionProcessingFees/reducer'
import SanctionFeesConfig from './SanctionFeesConfig/reducer'
import MemberFeesConfig from './MemberFeesConfig/reducer'
import MembershipRevenueBreakdown from './MembershipRevenueBreakdown/reducer'

const rootReducer = combineReducers({
    // public
    Login,
    loadingBar: loadingBarReducer,
    Layout,
    AnnualRenewFee,
    Club,
    ClubPlans,
    ClubSubUser,
    ClubUser,
    MemberFeesConfig,
    MembershipRevenueBreakdown,
    Pagination,
    ProcessingFee,
    SetupFee,
    Plan,
    SanctionFeesConfig,
    SanctionProcessingFees,
    SweepstakeVariety
})

export default rootReducer
