import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { Toast } from 'src/Atoms'
import Header from './VerticalLayout/Header'
import Footer from './VerticalLayout/Footer'

const NonAuthLayout = ({ children }) => {
    const { isAuth, loading } = useSelector((state) => state.Login)
    if (isAuth && !loading) {
        return <Navigate to={{ pathname: '/dashboard' }} />
    }
    return (
        <>
            <Header unAuth />
            <Toast />
            {children}
            <Footer unAuth />
        </>
    )
}
NonAuthLayout.propTypes = {
    children: PropTypes.node
}

export default NonAuthLayout
