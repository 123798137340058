import { createSlice } from '@reduxjs/toolkit'
import { PlanCreate, PlanLists, MembershipPlan } from './thunk'

export const PlanForm = {
  fee_id: 0,
  plan_title: '',
  no_of_members: 0,
  fee_amount: '',
  renew_fee_amount: '0',
  created_on: '',
  updated_on: '',
  is_deactivated: 0,
}
export const initialState = {
  form: PlanForm,
  membershipPlan: [],
  Plan: [],
  loading: true,
}
const Plan = createSlice({
  name: 'plan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Listing
    builder.addCase(PlanLists.pending, (state) => {
      state.loading = true
    })
    builder.addCase(PlanLists.fulfilled, (state, action) => {
      const { data } = action.payload
      state.form = PlanForm
      state.Plan = data
      state.loading = false
    })
    builder.addCase(PlanLists.rejected, (state) => {
      state.loading = false
    })

    // Create
    builder.addCase(PlanCreate.pending, (state) => {
      state.loading = false
    })
    builder.addCase(PlanCreate.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(PlanCreate.rejected, (state) => {
      state.loading = false
    })

    // MembershipPlanList
    builder.addCase(MembershipPlan.pending, (state) => {
      state.loading = true
    })
    builder.addCase(MembershipPlan.fulfilled, (state, action) => {
      const { data } = action.payload
      state.membershipPlan = data
      state.loading = false
    })
    builder.addCase(MembershipPlan.rejected, (state) => {
      state.loading = false
    })
  },
})

// export const {} = Plan.actions
export default Plan.reducer
