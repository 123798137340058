import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    ClubUserListApi,
    ClubUserByIdApi,
    ClubUserCreateApi,
    ClubUserDeleteApi,
    ClubUserUpdateApi
} from './ClubUser.services'
import { paginationData } from '../Pagination/reducers'

//services
export const ClubUserList = createAsyncThunk(
    'ClubUser/MembershipList',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await ClubUserListApi(data)

            if (data._limit) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data._page,
                    totalPage: TotalPage,
                    totalRecords: res?.totalRecords
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ClubUserCreate = createAsyncThunk(
    'ClubUser/create',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ClubUserCreateApi(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ClubUserUpdate = createAsyncThunk(
    'ClubUser/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ClubUserUpdateApi(data.age_classification_id, data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ClubUserById = createAsyncThunk(
    'ClubUser/one',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await ClubUserByIdApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ClubUserDelete = createAsyncThunk(
    'ClubUser/delete',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await ClubUserDeleteApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
