import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    ClubByIdApi,
    ClubCreateApi,
    ClubDeleteApi,
    ClubGrandTotalApi,
    ClubListApi,
    ClubPublishApi,
    ClubUpdateApi
} from './club.services'
import { paginationData } from '../Pagination/reducers'

//
export const ClubList = createAsyncThunk(
    'Club/List',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await ClubListApi(data)

            if (data._limit) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data._page,
                    totalPage: TotalPage,
                    totalRecords: res?.totalRecords
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//

export const ClubCreate = createAsyncThunk('Club/create', async (data, { rejectWithValue }) => {
    try {
        const res = await ClubCreateApi(data)
        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

//

export const ClubUpdate = createAsyncThunk('Club/update', async (data, { rejectWithValue }) => {
    try {
        const res = await ClubUpdateApi(data.ID, data)
        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

//
export const ClubById = createAsyncThunk('Club/getById', async (ID, { rejectWithValue }) => {
    try {
        const res = await ClubByIdApi(ID)
        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})
export const ClubGrandTotal = createAsyncThunk(
    'Club/GrandTotal',
    async (ID, { rejectWithValue }) => {
        try {
            const res = await ClubGrandTotalApi(ID)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//
export const ClubDelete = createAsyncThunk('Club/delete', async (ID, { rejectWithValue }) => {
    try {
        const res = await ClubDeleteApi(ID)
        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})
export const ClubPublish = createAsyncThunk('Club/publish', async (data, { rejectWithValue }) => {
    try {
        const res = await ClubPublishApi(data.ID, data)
        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})
