import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

// Auth
const Profile = lazy(() => import('../pages/Profile/Profile'))
const ChangePassword = lazy(() => import('../pages/ChangePassword/ChangePassword'))

const Dashboard = lazy(() => import('../pages/Dashboard/index'))
const Login = lazy(() => import('../pages/Login/Login'))

// Token
const Token = lazy(() => import('../pages/Token/Token'))

// Club List
const ClubList = lazy(() => import('../pages/ClubList/ClubList'))
const ClubForm = lazy(() => import('../pages/ClubList/Form'))

// Plan
const PlanList = lazy(() => import('src/pages/Plan/PlanList'))
const PlanForm = lazy(() => import('src/pages/Plan/Form'))

// Setup Fee
const SetUpFeeList = lazy(() => import('../pages/SetUpFee/SetUpFeeList'))
const SetUpFeeForm = lazy(() => import('../pages/SetUpFee/Form'))

// // ProcessingFees

// const ProcessingFees = lazy(() => import('../pages/ClubManagement/ProcessingFess'))

// Annual renew Fee
const AnnualRenewFeeList = lazy(() => import('../pages/AnnualRenewFee/AnnualRenewFee'))
const AnnualRenewFeeForm = lazy(() => import('../pages/AnnualRenewFee/Form'))
const ForgotPassword = lazy(() => import('../pages/ForgotPassword/ForgotPassword'))
const ClubPlanPurchased = lazy(() => import('src/pages/Club/ClubPlanPurchased/Index'))

// Club Plans
const ClubPlan = lazy(() => import('src/pages/Club/Plans/Plans'))

// // Membeship
// const MembershipList = lazy(() => import('src/pages/Member/MemberList/index'))
// const SubUserList = lazy(() => import('src/pages/SubMemberList'))
// const MembershipForm = lazy(() => import('src/pages/Member/MemberList/Form'))
// const AllSubMemberList = lazy(() => import('src/pages/Member/MemberList/AllSubMemberList'))

// const SanctionProcessingFess = lazy(() => import('src/pages/Sanction/ProcessingFess/index'))

const SanctionFeesConfig = lazy(() => import('src/pages/Sanction/FeeConfig'))
const SanctionFeesConfigForm = lazy(() => import('src/pages/Sanction/FeeConfig/Form'))

const MemberFeesConfig = lazy(() => import('src/pages/Member/FeeConfig'))
const MemberFeesConfigForm = lazy(() => import('src/pages/Member/FeeConfig/Form'))

// Revenue BreakDown
const RevenueBreakDown = lazy(() => import('src/pages/Member/RevenueBreakDown'))

const userRoutes = [
    { path: '/dashboard', component: <Dashboard />, name: 'Dashboard' },
    { path: '/dashboard/profile', component: <Profile />, name: 'Profile' },

    // Club
    { path: '/dashboard/club-list', component: <ClubList />, name: 'Club List' },
    { path: '/dashboard/club-list/create', component: <ClubForm />, name: 'Create ' },
    { path: '/dashboard/club-list/update/:ID', component: <ClubForm />, name: 'Update' },

    // Plan
    { path: '/dashboard/club-list/:clubId/plan', component: <PlanList />, name: 'View Plan' },
    { path: '/dashboard/club-list/:clubId/plan/create', component: <PlanForm />, name: 'Create' },
    {
        path: '/dashboard/club-list/:clubId/plan/update/:ID',
        component: <PlanForm />,
        name: 'Update'
    },

    // Member Fees

    // Setup fee
    {
        path: '/dashboard/set-up-fee',
        component: <SetUpFeeList />,
        name: 'Setup Fees'
    },
    {
        path: '/dashboard/set-up-fee/create',
        component: <SetUpFeeForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/set-up-fee/update/:ID',
        component: <SetUpFeeForm />,
        name: 'Update'
    },
    // Annual renew fee
    {
        path: '/dashboard/annual-renew-fee',
        component: <AnnualRenewFeeList />,
        name: 'Annual Renew fee'
    },
    {
        path: '/dashboard/annual-renew-fee/create',
        component: <AnnualRenewFeeForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/annual-renew-fee/update/:ID',
        component: <AnnualRenewFeeForm />,
        name: 'Update'
    },

    // Setup fee
    {
        path: '/dashboard/annual-renew-fee',
        component: <SetUpFeeList />,
        name: 'Setup Fees'
    },
    // {
    //     path: '/dashboard/processing_fees',
    //     component: <ProcessingFees />,
    //     name: 'Processing Fees'
    // },

    // {
    //     path: '/dashboard/membership',
    //     component: <MembershipList />,
    //     name: 'Member List'
    // },
    // {
    //     path: '/dashboard/membership/all_sub_member_list',
    //     component: <AllSubMemberList />,
    //     name: 'All Sub Member List'
    // },
    // {
    //     path: '/dashboard/membership/allsubuser/:ID',
    //     component: <SubUserList />,
    //     name: 'user'
    // },
    // {
    //     path: '/dashboard/membership/:ID',
    //     component: <MembershipForm />,
    //     name: 'Form'
    // },

    // {
    //     path: '/dashboard/sanction_processing_fees',
    //     component: <SanctionProcessingFess />,
    //     name: 'Sanction Processing Fees'
    // },
    {
        path: '/dashboard/sanction-fees-config',
        component: <SanctionFeesConfig />,
        name: 'Sanction Fees Config'
    },
    {
        path: '/dashboard/sanction-fees-config/create',
        component: <SanctionFeesConfigForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/sanction-fees-config/update/:ID',
        component: <SanctionFeesConfigForm />,
        name: 'Update'
    },

    //
    {
        path: '/dashboard/member-fees-config',
        component: <MemberFeesConfig />,
        name: 'Member Fees Config'
    },
    {
        path: '/dashboard/member-fees-config/create',
        component: <MemberFeesConfigForm />,
        name: 'Create'
    },
    {
        path: '/dashboard/member-fees-config/update/:ID',
        component: <MemberFeesConfigForm />,
        name: 'Update'
    },
    {
        path: '/dashboard/membership-revenue-breakdown',
        component: <RevenueBreakDown />,
        name: 'Revenue Breakdown'
    },
    // { path: '/dashboard/:id', component: <Dashboard />, id: "1", name: "Dashboard Detail" },
    {
        path: '/dashboard/change-password',
        component: <ChangePassword />,
        id: '120',
        name: 'Change Password'
    },

    {
        path: '/',
        exact: true,
        component: <Navigate to='/dashboard' />
    },
    { path: '*', component: <Navigate to='/dashboard' /> }
]

const clubRoutes = [
    { path: '/dashboard', component: <Dashboard />, name: 'Dashboard' },
    { path: '/dashboard/plans', component: <ClubPlan />, name: 'Plans' },
    {
        path: '/dashboard/club_plan_purchased',
        component: <ClubPlanPurchased />,
        name: ' Club Plan Purchased'
    }
]

// const PublicRoute = [{ path: '/login', component: <Login />, id: '2', name: 'Login' }]

const PublicRoute = [
    { path: '/login', component: <Login />, id: '1', name: 'Login' },
    { path: '/forgot-password', component: <ForgotPassword />, id: '2', name: 'Forgot Password' },
    { path: '*', component: <Navigate to='/login' />, id: '100' },
    {
        path: '/token',
        component: <Token />,
        id: '300',
        name: 'Token'
    }
]

export { userRoutes, PublicRoute, clubRoutes }
