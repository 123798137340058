import { createSlice } from '@reduxjs/toolkit'

export const AnnualRenewFeeForm = {
    from: '',
    to: '',
    amount: ''
}
export const initialState = {
    form: AnnualRenewFeeForm,
    loading: true
}
const AnnualRenewFee = createSlice({
    name: 'AnnualRenewFee',
    initialState,
    reducers: {},
    extraReducers: () => {
        // Listing
    }
})

// export const {} = SetupFee.actions
export default AnnualRenewFee.reducer
