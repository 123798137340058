import { createAsyncThunk } from '@reduxjs/toolkit'
import { PlanCreateApi, PlanListApi, MembershipPlanList } from './plan.services'
import { paginationData } from '../Pagination/reducers'

//
export const PlanLists = createAsyncThunk(
    'Plan/List',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            console.log(`🚀 ~ file: thunk.js:13 ~ data:`, data)
            const res = await PlanListApi(data)

            if (data._limit) {
                // Pagination Section
                const limit = data._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data._page,
                    totalPage: TotalPage,
                    totalRecords: res?.totalRecords
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

//

export const PlanCreate = createAsyncThunk('Plan/create', async (data, { rejectWithValue }) => {
    try {
        const res = await PlanCreateApi(data)
        return res
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

//

export const MembershipPlan = createAsyncThunk(
    'MembershipPlan/list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await MembershipPlanList(data)
            return res
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
