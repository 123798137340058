import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

//

export const ClubUserListApi = (data) => API.get(`${URL.CLUB_PLANS_LIST}`, data)

export const ClubUserByIdApi = (ID) => API.get(`${URL.CLUB_PLANS_LIST}/${ID}`)

export const ClubUserCreateApi = (data) => API.post(`${URL.CLUB_PLANS_LIST}`,data)

export const ClubUserUpdateApi = (ID,data) => API.put(`${URL.CLUB_PLANS_LIST}/${ID}`,data)

export const ClubUserDeleteApi = (ID) =>API.del(`${URL.CLUB_PLANS_LIST}/${ID}`)